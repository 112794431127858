export default {
  PRODUCTS_LIST: "Product List",
  ADD_PRODUCT: "Add Product",
  PRODUCT_ADDED: "Product Added",
  EDIT_PRODUCT: "Edit Product",
  PRODUCT_UPDATED: "Product Updated",
  DELETE_THIS_PRODUCT: "Delete this product?",
  PRODUCT_DELETED: "Product Deleted",
  SYNC_PRODUCT: "Synchronize products",
  RUN_SYNCHRONIZE_PRODUCTS: "Run synchronization",
};
