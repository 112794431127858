export default {
  SUPPLIERS_LIST: "Suppliers list",
  ADD_SUPPLIER: "Add supplier",
  SUPPLIER_ADDED: "Supplier added",
  EDIT_SUPPLIER: "Edit supplier",
  SUPPLIER_UPDATED: "Supplier updated",
  DELETE_THIS_SUPPLIER: "Delete this supplier?",
  SUPPLIER_DELETED: "Supplier deleted",
  ACTIVE: "Active",
  ACTIVATE: "Activate",
  DEACTIVATE: "Deactivate",
  SUPPLIER_ACTIVATED: "Supplier Activated",
  SUPPLIER_DEACTIVATED: "Supplier Deactivated",
};
