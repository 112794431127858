export default {
  SUPPLY_RULE_NAME: "Rule name",
  SUPPLY_RULE_DESCRIPTION: "Rule description",
  SUPPLY_RULES_LIST: "List of supply rules",
  ADD_SUPPLY_RULE: "Add a supply rule",
  SUPPLY_RULE_ADDED: "Supply rule added",
  EDIT_SUPPLY_RULE: "Edit a supply rule",
  SUPPLY_RULE_UPDATED: "Supply rule updated",
  DELETE_THIS_SUPPLY_RULE: "Delete this supply rule?",
  SUPPLY_RULE_DELETED: "Supply rule deleted",
  SUPPLY_RULE_EXCERPT: "Supply rule note",
  EXCERPT: "Note",
  WEBSITE_URL: "Website",
  SUPPLY_RULE_MIN_QUANTITY: "Min Quantity",
  SUPPLY_RULE_MAX_QUANTITY: "Max Quantity",
  SUPPLY_RULE_ORDER_DELAY: "Delivery time (days)",
  SUPPLY_RULE_ORDER_METHOD: "Method",
  SUPPLY_RULE_ORDER_BEFORE_HOUR: "Maximum time to order",
  SUPPLY_RULE_ORDER_BEFORE_DATE: "Order before",
  SUPPLY_RULE_DELIVERY_DATES: "Delivery dates",
  SUPPLY_RULE_DELIVERY_DAYS: "Delivery days",
  SUPPLY_RULE_ORDER_DAYS: "Order days",
  SUPPLY_RULE_DELIVERY_DAY_0: "Sunday",
  SUPPLY_RULE_DELIVERY_DAY_1: "Monday",
  SUPPLY_RULE_DELIVERY_DAY_2: "Tuesday",
  SUPPLY_RULE_DELIVERY_DAY_3: "Wednesday",
  SUPPLY_RULE_DELIVERY_DAY_4: "Thursday",
  SUPPLY_RULE_DELIVERY_DAY_5: "Friday",
  SUPPLY_RULE_DELIVERY_DAY_6: "Saturday",
  SUPPLY_RULE_ORDER_METHOD_SMS: "SMS",
  SUPPLY_RULE_ORDER_METHOD_EMAIL: "Email",
  SUPPLY_RULE_ORDER_METHOD_PHONE: "Phone",
  SUPPLY_RULE_ORDER_METHOD_ONLINE_ORDER: "Order online",
  SUPPLY_RULE_ORDER_METHOD_INTERNAL: "Internal",
  SUPPLY_RULE_DELIVERY_DATE: "Delivery date",
  SUPPLY_RULE_ORDER_DATE: "Order date",
  SUPPLY_RULE_DATE_EXPIRED: "Date expired",
  SUPPLY_RULE_INVALID_ORDER_DATE: "Invalid order date",
};
