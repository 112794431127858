export default {
  ESTABLISHMENTS_LIST: "Establishments list",
  ADD_ESTABLISHMENT: "Add establishment",
  ESTABLISHMENT_ADDED: "Establishment added",
  EDIT_ESTABLISHMENT: "Edit establishment",
  ESTABLISHMENT_UPDATED: "Establishment updated",
  DELETE_THIS_ESTABLISHMENT: "Delete this establishment ?",
  ESTABLISHMENT_DELETED: "Establishment deleted",
  BILLING_INFORMATIONS_SAME_AS_ESTABLISHMENT: "Same as establishment",
  ESTABLISHMENT_TYPE_ELEMENTARY: "Elementary",
  ESTABLISHMENT_TYPE_SECONDARY: "Secondary",
  ESTABLISHMENT_TYPE_COLLEGE: "College",
  ESTABLISHMENT_TYPE_UNIVERSITY: "University",
  ACTIVATE: "Activate",
  DESACTIVATE: "Desactivate",
  ESTABLISHMENT_TYPE: "Establishment type",
};
