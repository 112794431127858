export default {
  IMPORTS_LIST: "List of imports",
  IMPORTS_PREVIEW: "See preview",
  ADD_IMPORT: "Add an import",
  IMPORT_ADDED: "Imports added",
  EDIT_IMPORT: "Edit an import",
  IMPORT_UPDATED: "Imports updated",
  DELETE_THIS_IMPORT: "Delete this import?",
  IMPORT_DELETED: "Imports deleted",
  IMPORT_MODEL: "Model",
  CHOOSE_MODEL: "Choose an import model",
  IMPORT_NAME: "Name",
  IMPORT_FILE_SIZE: "File size",
  COLUMN_ROW_COUNT: "Import rows",
  SHOW_ROWS: "Import data",
  COUNT_CSV_ROWS: "Number of rows",
  IMPORT_PREVIEW_DATA: "Preview of data to import",
  CSV_PREVIEW_DATA: "Preview file data",
  FILE: "File",
  RUN_DRY_IMPORT: "Test import",
  RUN_IMPORT: "Start import",
  RESULTS_ON_X_LINES: "Results for a total of {linesCount} lines",
  SUCCESS: "Successes",
  FAILURES: "Failure",
  X_ROW: "Line {line}",
  LINE: "Line",
  DATA: "Data",
  ERROR: "Error",
  MODEL_ID: "ID",
  RESULTS: "Results",
  IMPORTED_ITEMS: "Imported items",
  ITEM: "Object",
  MODEL_FIELD: "Model field",
  STATUS: "STATUS",
  UPDATED: "Updated",
  CREATED: "New",
  DELETED: "Deleted",
  CSV_FIELD: "CSV file field",
  ADD_SYNCHRONIZATION: "Add synchronization",
  IDENTIFY: "Identifier",
  RUN_DRY_SYNC: "Test synchronization",
  IDENTIFIERS: "Identifiers",
  IDENTIFIERS_EXPLAIN:
    "These fields are identifiers that will be used to identify each line of the csv file",
};
